import styled from 'styled-components';

export const BottomMenuContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: var(--bottom-menu-height, 60px);
  min-height: 10px !important;
  background-color: ${props => props.theme.colors.background};
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  flex-grow: 1;
`;

export const MenuButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  opacity: ${props => props.active ? 1 : 0.5};
  transition: opacity 0.3s ease, color 0.3s ease;
  color: ${props => props.active ? props.theme.colors.primary : props.theme.colors.text};
  position: relative;

  width: 33.33%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    outline: none;
  }

  -webkit-tap-highlight-color: transparent;

  &:focus-visible {
    box-shadow: 0 0 0 2px ${props => props.theme.colors.primary};
  }

  &:hover {
    color: ${props => props.theme.colors.secondary};
  }
`;

export const ChatDiscussionButton = styled.button`
  position: absolute;
  top: -1.25rem;
  left: 0.625rem;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.background};
  border: none;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${props => props.theme.fontSizes.large};
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, background-color 0.3s ease;

  &:hover {
    transform: scale(1.1);
    background-color: ${props => props.theme.colors.secondary};
  }

  &:focus {
    outline: 2px solid ${props => props.theme.colors.secondary};
    outline-offset: 2px;
  }
`;

export const HistoryButton = styled(ChatDiscussionButton)`
  left: auto;
  right: 0.625rem;
`;