import styled from 'styled-components';

export const PodiumContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 200px;
  margin-bottom: 10px;
  width: 100%;
  top: -40%;
`;

export const QuestionCard = styled.div`
  background-color: ${props => props.theme.colors.lightGray};
  border-radius: 25px;
  padding: ${props => props.theme.spacing.xlarge};
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  width: 95%;
  aspect-ratio: 1 / 1.1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${props => props.theme.spacing.xlarge};
`;

export const PodiumStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 33%;
  background-color: transparent;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 7px;
  margin: 0 1px;
`;

export const FormationName = styled.span`
  font-weight: bold;
  text-align: center;
  margin-bottom: 3px;
  font-size: 12px;
`;

export const FormationScore = styled.span`
  font-size: 11px;
`;

export const FirstPlace = styled(PodiumStep)`
  height: 160px;
  background-color: gold;
`;

export const SecondPlace = styled(PodiumStep)`
  height: 120px;
  background-color: silver;
`;

export const ThirdPlace = styled(PodiumStep)`
  height: 80px;
  background-color: #cd7f32;
`;

export const ActionButton = styled.button`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${props => props.theme.colors.primaryDark};
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 20px;
`;
