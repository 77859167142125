import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { IoMenu, IoRefresh } from "react-icons/io5";
import ChatHistory from '../ChatHistory';
import useDeviceType from '../useDeviceType';
import {
    SideMenuContainer,
    TopSection,
    HeaderContainer,
    BottomNavList,
    NavItem,
    RestartButton,
    HistoryContainer,
    MenuButton,
    MainContentWrapper
} from './styles';

const SideMenu = ({ isOpen, onToggle, onRestartChat, onDiscussionSelect, children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { isMobile } = useDeviceType();

    const handleNavigation = (path) => {
        navigate(path);
        if (isMobile) onToggle();
    };

    const handleItemClick = (discussion) => {
        console.log('Discussion clicked:', discussion);
        if (onDiscussionSelect) {
            onDiscussionSelect(discussion);
        }
        if (location.pathname !== '/chat') {
            navigate('/chat', { state: { selectedDiscussion: discussion } });
        }
        if (isMobile) onToggle();
    };

    return (
        <>
            {!isMobile && (
                <MenuButton onClick={onToggle} style={{ position: 'fixed', top: '10px', left: '10px', zIndex: 1001 }}>
                    <IoMenu size={24} />
                </MenuButton>
            )}
            <SideMenuContainer $isOpen={isOpen}>
                <HeaderContainer>
                    {isMobile ? (
                        <MenuButton onClick={onToggle}>
                            <IoMenu size={24} />
                        </MenuButton>
                    ) : (
                        <div /> // Empty div for spacing
                    )}
                    <RestartButton onClick={onRestartChat}>
                        <IoRefresh size={24} />
                    </RestartButton>
                </HeaderContainer>
                <TopSection>
                    <HistoryContainer>
                        <ChatHistory onDiscussionClick={handleItemClick} />
                    </HistoryContainer>
                </TopSection>
                <BottomNavList>
                    <NavItem>
                        <button onClick={() => handleNavigation('/chat')}>Chat</button>
                    </NavItem>
                    <NavItem>
                        <button onClick={() => handleNavigation('/classement')}>Profile</button>
                    </NavItem>
                    <NavItem>
                        <button onClick={() => handleNavigation('/quiz')}>Quiz</button>
                    </NavItem>
                </BottomNavList>
            </SideMenuContainer>
            <MainContentWrapper $isOpen={isOpen} $isMobile={isMobile}>
              {children}
            </MainContentWrapper>
        </>
    );
};

export default SideMenu;