import React, { useEffect, useRef, useCallback } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useSpring, animated, config } from '@react-spring/web';
import { useNavigate } from 'react-router-dom';
import {
  QuizContainer,
  BackgroundBall,
  QuestionCard,
  QuestionText,
  AnswerOptionsContainer,
  AnswerButton,
  QuizMessage,
  StyledThumbsUp,
  StyledThumbsDown,
  StyledHelpCircle,
  StyledHeart,
  QuizCompletionMessage,
  ResultButton,
  StyledRanking
} from './styles';
import apiCall from '../../utils/api';
import useDeviceType from '../useDeviceType';
import { useQuiz } from '../../contexts/QuizContext';



const SWIPE_THRESHOLD = 10;

const Quiz = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { isMobile } = useDeviceType();

  const {
    currentQuestionIndex,
    setCurrentQuestionIndex,
    quizCompleted,
    setQuizCompleted,
    questions,
    setQuestions,
    answers,
    setAnswers,
    setQuizResult,
    isQuizInitialized,
    setIsQuizInitialized
  } = useQuiz();

  const [{ x, y }, api] = useSpring(() => ({ x: 0, y: 0 }));

  const cardRef = useRef(null);
  const touchStartRef = useRef({ x: 0, y: 0 });

  const fetchQuestions = useCallback(async () => {
      try {
        const response = await apiCall(`${process.env.REACT_APP_BACKEND_URL}/quiz/questions/`, 'GET');
        setQuestions(response);
        setIsQuizInitialized(true);
      } catch (error) {
        console.error('Error fetching questions:', error);
      }
    }, [setQuestions, setIsQuizInitialized]);

      useEffect(() => {
          if (isAuthenticated && !isQuizInitialized) {
            fetchQuestions();
          }
        }, [isAuthenticated, isQuizInitialized, fetchQuestions]);

  const handleSeeResults = () => {
      navigate('/classement');
    };

   const handleAnswer = async (answer, direction) => {
      const questionId = currentQuestionIndex + 1;
      const newAnswers = { ...answers, [`q${questionId}`]: answer };
      setAnswers(newAnswers);

      try {
        await apiCall(`${process.env.REACT_APP_BACKEND_URL}/quiz/answer/`, 'POST', {
          question_id: questionId,
          answer: answer
        });
      } catch (error) {
        console.error('Error saving answer:', error);
      }

      animateCard(direction);

      setTimeout(() => {
        if (currentQuestionIndex < questions.length - 1) {
          setCurrentQuestionIndex(prevIndex => prevIndex + 1);
          api.start({ x: 0, y: 0, immediate: true });
        } else {
          submitQuiz();
        }
      }, 300);
    };

  const animateCard = (direction) => {
    const distance = window.innerWidth + 100;
    switch (direction) {
      case 'up':
        api.start({ y: -distance, config: config.wobbly });
        break;
      case 'down':
        api.start({ y: distance, config: config.wobbly });
        break;
      case 'left':
        api.start({ x: -distance, config: config.wobbly });
        break;
      case 'right':
        api.start({ x: distance, config: config.wobbly });
        break;
      default:
        api.start({ x: 0, y: 0 });
    }
  };

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    touchStartRef.current = { x: touch.clientX, y: touch.clientY };
  };

  const handleTouchMove = (e) => {
    if (!cardRef.current) return;

    const touch = e.touches[0];
    const deltaX = touch.clientX - touchStartRef.current.x;
    const deltaY = touch.clientY - touchStartRef.current.y;

    api.start({ x: deltaX, y: deltaY, immediate: true });
  };

  const handleTouchEnd = (e) => {
    const touch = e.changedTouches[0];
    const deltaX = touch.clientX - touchStartRef.current.x;
    const deltaY = touch.clientY - touchStartRef.current.y;

    if (Math.abs(deltaX) > SWIPE_THRESHOLD || Math.abs(deltaY) > SWIPE_THRESHOLD) {
      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        // Horizontal swipe
        if (deltaX > 0) {
          handleAnswer(1, 'right'); // Yes
        } else {
          handleAnswer(2, 'left'); // No
        }
      } else {
        // Vertical swipe
        if (deltaY > 0) {
          handleAnswer(3, 'down'); // I don't know
        } else {
          handleAnswer(4, 'up'); // Love
        }
      }
    } else {
      api.start({ x: 0, y: 0, config: config.wobbly });
    }
  };

  const submitQuiz = useCallback(async () => {
    setQuizCompleted(true);
    try {
      const response = await apiCall(`${process.env.REACT_APP_BACKEND_URL}/quiz/submit/`, 'POST');
      setQuizResult(response);
    } catch (error) {
      console.error('Error submitting quiz:', error);
    }
  }, [setQuizCompleted, setQuizResult]);

  useEffect(() => {
    if (currentQuestionIndex >= questions.length && questions.length > 0) {
      submitQuiz();
    }
  }, [currentQuestionIndex, questions, submitQuiz]);

  //const restartQuiz = () => {
  //  setQuizCompleted(false);
  //  setQuizResult(null);
  //  setCurrentQuestionIndex(0);
  //  setAnswers({});
  //  fetchQuestions();
  //};

  //const viewPreviousResults = async () => {
  //  try {
  //    const response = await apiCall(`${process.env.REACT_APP_BACKEND_URL}/quiz/previous-results/`, 'GET');
  //    console.log('Previous results:', response);
  //    // Handle displaying previous results, e.g., open a modal or navigate to a new page
  //  } catch (error) {
  //    console.error('Error fetching previous results:', error);
  //  }
  //};

  useEffect(() => {
    if (currentQuestionIndex >= questions.length && questions.length > 0) {
      submitQuiz();
    }
  }, [currentQuestionIndex, questions, submitQuiz]);

  if (!isAuthenticated) {
    return <QuizMessage>Please log in to take the quiz.</QuizMessage>;
  }

  if (questions.length === 0) {
    return <QuizMessage>Loading questions...</QuizMessage>;
  }

   if (quizCompleted) {
     return (
       <QuizContainer>
         <QuizCompletionMessage>
           Bravo ! Tu as fini le quiz de Meoria.
           Fonce voir tes résultats !
         </QuizCompletionMessage>
         <ResultButton onClick={handleSeeResults}>
           <StyledRanking />
           Classement
         </ResultButton>
       </QuizContainer>
     );
   }

  if (currentQuestionIndex >= questions.length) {
    return <QuizMessage>Submitting your answers...</QuizMessage>;
  }

  //const currentQuestion = questions[currentQuestionIndex];


  if (questions.length === 0) {
    return <QuizMessage>Loading questions...</QuizMessage>;
  }

  return (
      <>
        <QuizContainer isMobile={isMobile}>
          <BackgroundBall isMobile={isMobile}/>
          <animated.div
            ref={cardRef}
            style={{ x, y, touchAction: 'none' }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <QuestionCard isMobile={isMobile}>
              <QuestionText>{questions[currentQuestionIndex]?.text}</QuestionText>
            </QuestionCard>
          </animated.div>
          <AnswerOptionsContainer isMobile={isMobile}>
            <AnswerButton onClick={() => handleAnswer(3, 'down')} aria-label="I don't know">
              <StyledHelpCircle />
            </AnswerButton>
            <AnswerButton onClick={() => handleAnswer(2, 'left')} aria-label="No">
              <StyledThumbsDown />
            </AnswerButton>
            <AnswerButton onClick={() => handleAnswer(1, 'right')} aria-label="Yes">
              <StyledThumbsUp />
            </AnswerButton>
            <AnswerButton onClick={() => handleAnswer(4, 'up')} aria-label="I love it">
              <StyledHeart />
            </AnswerButton>
          </AnswerOptionsContainer>
        </QuizContainer>
      </>
    );
  };

  export default Quiz;