import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  ContentWrapper,
  UserInfo,
  LogoutButton,
  Title,
  SettingsSection,
  SectionTitle,
  SettingsItem,
  SettingsLabel,
  SettingsValue,
  ReturnButton,
  SocialLinksContainer,
  SocialLink,
  RestartQuizButton,
  ConfirmationModal,
  ModalContent,
  ModalButtons,
  ConfirmButton,
  CancelButton
} from './styles';
import LogoutConfirmation from '../LogoutConfirmationPopUp';
import { FaTiktok, FaInstagram, FaYoutube, FaEnvelope, FaRedo, FaSignOutAlt, FaArrowLeft } from 'react-icons/fa';
import apiCall from '../../utils/api';
import { useQuiz } from '../../contexts/QuizContext';

const Settings = () => {
  const { user, logout } = useAuth();
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [showRestartConfirmation, setShowRestartConfirmation] = useState(false);
  const navigate = useNavigate();
  const { resetQuiz } = useQuiz();

  const handleLogoutClick = () => {
    setShowLogoutConfirmation(true);
  };

  const handleLogoutConfirm = () => {
    logout();
    setShowLogoutConfirmation(false);
    navigate('/login');
  };

  const handleLogoutCancel = () => {
    setShowLogoutConfirmation(false);
  };

  const handleReturn = () => {
    navigate('/classement');
  };

  const handleRestartQuiz = () => {
      setShowRestartConfirmation(true);
    };

    const handleRestartConfirm = async () => {
        try {
          await apiCall(`${process.env.REACT_APP_BACKEND_URL}/quiz/reset-quiz/`, 'POST');
          resetQuiz(); // Reset all quiz-related state
          setShowRestartConfirmation(false);
          navigate('/quiz');
        } catch (error) {
          console.error('Error resetting quiz:', error);
          // Handle error (e.g., show an error message to the user)
        }
      };

      const handleRestartCancel = () => {
        setShowRestartConfirmation(false);
      };

  return (
    <Container>
      <ReturnButton onClick={handleReturn}>
        <FaArrowLeft />
      </ReturnButton>
      <Title>Paramètres</Title>
      <ContentWrapper>
        {user && (
          <>
            <SettingsSection>
              <SectionTitle>Informations du profil</SectionTitle>
              <UserInfo>
                <SettingsItem>
                  <SettingsLabel>Pseudo:</SettingsLabel>
                  <SettingsValue>{user.pseudo}</SettingsValue>
                </SettingsItem>
                <SettingsItem>
                  <SettingsLabel>Email:</SettingsLabel>
                  <SettingsValue>{user.email}</SettingsValue>
                </SettingsItem>
              </UserInfo>
            </SettingsSection>

            <SettingsSection>
              <SectionTitle>Actions du compte</SectionTitle>
              <RestartQuizButton onClick={handleRestartQuiz}>
                <FaRedo />
                Recommencer le quiz
              </RestartQuizButton>
              <LogoutButton onClick={handleLogoutClick}>
                <FaSignOutAlt />
                Se déconnecter
              </LogoutButton>
            </SettingsSection>

            <LogoutConfirmation
              isOpen={showLogoutConfirmation}
              onClose={handleLogoutCancel}
              onConfirm={handleLogoutConfirm}
            />

            <ConfirmationModal isOpen={showRestartConfirmation}>
                    <ModalContent>
                      <h2>Confirmer la réinitialisation du quiz</h2>
                      <p>Êtes-vous sûr de vouloir recommencer le quiz ? Toutes vos réponses précédentes seront supprimées.</p>
                      <ModalButtons>
                        <ConfirmButton onClick={handleRestartConfirm}>Confirmer</ConfirmButton>
                        <CancelButton onClick={handleRestartCancel}>Annuler</CancelButton>
                      </ModalButtons>
                    </ModalContent>
                  </ConfirmationModal>
          </>
        )}
      </ContentWrapper>
      <SocialLinksContainer>
        <SocialLink href="https://www.tiktok.com/@meoria.app" target="_blank" rel="noopener noreferrer">
          <FaTiktok />
        </SocialLink>
        <SocialLink href="https://www.instagram.com/meoria.app/" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </SocialLink>
        <SocialLink href="https://www.youtube.com/@meoria-app" target="_blank" rel="noopener noreferrer">
          <FaYoutube />
        </SocialLink>
        <SocialLink href="contact@meoria.fr">
          <FaEnvelope />
        </SocialLink>
      </SocialLinksContainer>
    </Container>
  );
};

export default Settings;
