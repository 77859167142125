import React, { useState, useRef, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import axios from 'axios';
import rehypeExternalLinks from 'rehype-external-links'
import { getAuthToken } from '../../utils/auth';
import { ClipLoader } from 'react-spinners';
import WelcomeMenu from '../WelcomeMenu';
import {
  Container,
  ChatContainer,
  ChatBox,
  ChatHistory,
  Message,
  InputArea,
  Input,
  Button,
  MarkdownBox,
  InputAreaWrapper,
  MenuBand,
  MenuButton,
  NewChatButton
} from './styles';
import { FaArrowUp } from "react-icons/fa";
import { FaRegPlusSquare } from "react-icons/fa";
import { useAuth } from '../../contexts/AuthContext';
import PopUp from '../SaveLoginPopup';
import MobileHistoryTab from '../MobileHistoryTab';
import useDeviceType from '../useDeviceType';
import { useChat } from '../../contexts/ChatContext';


const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ChatDiscussion = forwardRef(({ onStartNewChat, toggleMobileHistory, isSideMenuOpen }, ref) => {
  const location = useLocation();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const chatHistoryRef = useRef(null);
  const [isMobileHistoryOpen, setIsMobileHistoryOpen] = useState(false);
  const [, setIsHistoryOpen] = useState(false);
  const { user, isAuthenticated } = useAuth();
  const [streamingMessage, setStreamingMessage] = useState('');
  const streamingMessageRef = useRef(null);
  const { isMobile } = useDeviceType();
  const isUnmounting = useRef(false);
  let streamingCompleted = false;
  const {
      chatHistory, setChatHistory,
      discussion, setDiscussion,
      inputText, setInputText,
      showWelcomeMenu, setShowWelcomeMenu,
      setContextHistory,
      setBeginDate,
      resetChat
    } = useChat();

  useEffect(() => {
    isUnmounting.current = false;
    return () => {
      isUnmounting.current = true;
    };
  }, []);

  useEffect(() => {
    const scrollToBottom = () => {
      if (chatHistoryRef.current) {
        chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
      }
    };

    scrollToBottom();

    const observer = new MutationObserver(scrollToBottom);
    if (chatHistoryRef.current) {
      observer.observe(chatHistoryRef.current, { childList: true, subtree: true });
    }

    return () => observer.disconnect();
  }, [chatHistory, streamingMessage]);

  useImperativeHandle(ref, () => ({
    resetChat: handleStartNewChat,
    loadDiscussion: loadDiscussion
  }));

  const toggleHistory = () => {
    if (isMobile) {
      setIsMobileHistoryOpen(prev => !prev);
    } else {
      setIsHistoryOpen(prev => !prev);
    }
  };

  const adjustTextareaHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      const newHeight = Math.min(inputRef.current.scrollHeight, 150);
      inputRef.current.style.height = `${newHeight}px`;

      // Adjust the textarea's position within its container
      inputRef.current.style.top = `${150 - newHeight}px`;
    }
  };



  useEffect(() => {
    adjustTextareaHeight();
  }, [inputText]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputText(newValue);
    adjustTextareaHeight();
  };

  // Wrap loadDiscussion in useCallback
  const loadDiscussion = useCallback(async (discussionId) => {
    setIsLoading(true);
    try {
      const token = getAuthToken();
      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/inference/get_single_discussion/`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          params: { discussion_id: discussionId },
        }
      );

      console.log('Response status:', response.status);
      console.log('Response data:', response.data);

      if (response.status === 200) {
        const { messages, last_context, begin_date } = response.data;
        setChatHistory(messages.map(msg => ({ user: msg.is_user ? 'You' : 'Bot', message: msg.content })));
        setContextHistory(last_context);
        setBeginDate(begin_date);
        setDiscussion(discussionId);
      } else {
        console.error('Unexpected response status:', response.status);
      }
    } catch (error) {
      console.error('Error loading discussion:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
      }
    } finally {
      setIsLoading(false);
    }
  }, [setChatHistory, setDiscussion, setBeginDate, setContextHistory]);



  const handleStartNewChat = useCallback(() => {
    if (!isAuthenticated) {
      setIsPopUpOpen(true);
      return;
    }
    resetChat();
  }, [isAuthenticated, resetChat]);

  useEffect(() => {
    if (location.state?.selectedDiscussion) {
      loadDiscussion(location.state.selectedDiscussion.id);
      setShowWelcomeMenu(false);
    }
  }, [location.state, loadDiscussion, setShowWelcomeMenu]);

  const saveDiscussionToLocalStorage = (newDiscussion) => {
    const savedDiscussions = JSON.parse(localStorage.getItem('chatDiscussions')) || [];
    const updatedDiscussions = [...savedDiscussions, newDiscussion];
    localStorage.setItem('chatDiscussions', JSON.stringify(updatedDiscussions));
  };

  const handleExampleClick = (example) => {
    setInputText(example);
    handleSubmit(null, example);
  };

  const handleSubmit = async (e, exampleText = null) => {
    // Prevent default form submission only if it's a real event
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const messageToSend = exampleText || inputText.trim();

    if (!messageToSend) {
      alert('Please enter a valid message before sending.');
      return;
    }

    if (!isAuthenticated) {
      setIsPopUpOpen(true);
      return;
    }

    setShowWelcomeMenu(false);

    const newUserMessage = { user: 'You', message: messageToSend };
    setChatHistory(prevHistory => [...prevHistory, newUserMessage]);
    setInputText('');
    setIsLoading(true);
    setStreamingMessage('');

    try {
      let currentDiscussion = discussion;

      if (!currentDiscussion) {
        const newDiscussionResponse = await axios.post(`${REACT_APP_BACKEND_URL}/inference/start_new_discussion/`, {
          user_id: user.id,
        }, {
          withCredentials: true
        });

        if (newDiscussionResponse.status === 200) {
          currentDiscussion = newDiscussionResponse.data.new_discussion_id;
          setDiscussion(currentDiscussion);
        } else {
          throw new Error('Failed to create a new discussion');
        }
      }

       const response = await fetch(`${REACT_APP_BACKEND_URL}/inference/inference_view/`, {
             method: 'POST',
             headers: {
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${getAuthToken()}`,
             },
             body: JSON.stringify({
               input: messageToSend,
               discussion: currentDiscussion,
               user_id: user.id,
             }),
           });

           const reader = response.body.getReader();
           const decoder = new TextDecoder();

           let fullResponse = '';
           while (true) {
             const { done, value } = await reader.read();
             console.log('Streaming completed:', streamingCompleted);

             if (done) {
               // Stream is complete, update the chat history
               streamingCompleted = true;
               console.log('Streaming completed:', streamingCompleted);
               const newBotMessage = { user: 'Bot', message: fullResponse.trim() };
               setChatHistory(prevHistory => {
                 const updatedHistory = [...prevHistory, newBotMessage];

                 // Save to local storage
                 const newDiscussionEntry = {
                   title: `Chat from ${new Date().toLocaleString()}`,
                   content: updatedHistory
                 };
                 saveDiscussionToLocalStorage(newDiscussionEntry);

                 return updatedHistory;
               });
               setStreamingMessage('');
               break;
             }

             const chunk = decoder.decode(value);
             const lines = chunk.split('\n');
             for (const line of lines) {
               if (line.startsWith('data: ')) {
                 try {
                   const data = JSON.parse(line.slice(5));
                   if (data.chunk) {
                     fullResponse += data.chunk;
                     setStreamingMessage(fullResponse);
                   }
                 } catch (error) {
                   console.error('Error parsing chunk:', error);
                 }
               }
             }
           }

         } catch (error) {
             console.error('Error during inference:', error);
             if (!streamingCompleted) {
             console.log('Streaming completed:', streamingCompleted);
               setChatHistory(prevHistory => [...prevHistory, { user: 'Bot', message: "Je suis momentanément indisponible. Veuillez réessayer plus tard. Nous réparons cette erreur au plus vite.\n\nMEORIA, le staff ;)" }]);
             }
           } finally {
             setIsLoading(false);
           }
         };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <Container isSideMenuOpen={!isMobile && isSideMenuOpen} isMobile={isMobile}>
      {isMobile && (
        <MenuBand>
          <MenuButton onClick={toggleHistory}>
          </MenuButton>
          <MenuButton onClick={handleStartNewChat}>
            <FaRegPlusSquare size={24} />
          </MenuButton>
        </MenuBand>
      )}
      {isMobile && (
        <MobileHistoryTab
          isOpen={isMobileHistoryOpen}
          onClose={toggleHistory}
        />
      )}
      <NewChatButton onClick={handleStartNewChat}>
                  New Chat
                </NewChatButton>

      <ChatContainer showWelcomeMenu={showWelcomeMenu} isMobile={isMobile} isSideMenuOpen={!isMobile && isSideMenuOpen}>
        <ChatBox>
          <ChatHistory ref={chatHistoryRef}>
            {showWelcomeMenu ? (
              <WelcomeMenu
                onExampleClick={handleExampleClick}
                isSideMenuOpen={!isMobile && isSideMenuOpen}
                isMobile={isMobile}
              />
            ) : (
              <>
                {chatHistory.map((chat, index) => (
                  <Message key={index} $user={chat.user}>
                    <MarkdownBox>
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        rehypePlugins={[
                          [rehypeExternalLinks, { target: '_blank', rel: ['nofollow', 'noopener', 'noreferrer'] }]
                        ]}
                      >
                        {chat.message}
                      </ReactMarkdown>
                    </MarkdownBox>
                  </Message>
                ))}
                {streamingMessage && (
                  <Message $user="Bot" ref={streamingMessageRef}>
                    <MarkdownBox>
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        rehypePlugins={[
                          [rehypeExternalLinks, { target: '_blank', rel: ['nofollow', 'noopener', 'noreferrer'] }]
                        ]}
                      >
                        {streamingMessage}
                      </ReactMarkdown>
                    </MarkdownBox>
                  </Message>
                )}
                {isLoading && !streamingMessage && (
                  <Message $user="Bot">
                    <ClipLoader color="#1565c0" size={20} />
                  </Message>
                )}
              </>
            )}
          </ChatHistory>
        </ChatBox>
      </ChatContainer>
      <InputAreaWrapper
        isMobile={isMobile}
        showWelcomeMenu={showWelcomeMenu}
        isSideMenuOpen={!isMobile && isSideMenuOpen}
      >
        <InputArea as="form" onSubmit={handleSubmit} isMobile={isMobile} isSideMenuOpen={!isMobile && isSideMenuOpen}>
          <Input
            ref={inputRef}
            as="textarea"
            value={inputText}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            placeholder="Message à Meoria..."
            disabled={isLoading}
            rows={1}
            isSideMenuOpen={!isMobile && isSideMenuOpen}
          />
          <Button
            type="submit"
            disabled={isLoading}
            show={inputText.trim().length > 0}
          >
            <FaArrowUp size={21} />
          </Button>
        </InputArea>
      </InputAreaWrapper>
      <PopUp
        isOpen={isPopUpOpen}
        onClose={() => setIsPopUpOpen(false)}
        message="Veuillez vous connecter pour utiliser cette fonctionnalité"
        onLogin={() => navigate('/login')}
      />
    </Container>
  );
});

export default ChatDiscussion;
