import React, { createContext, useState, useContext, useEffect } from 'react';

const QuizContext = createContext();

export const QuizProvider = ({ children }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [quizResult, setQuizResult] = useState(null);
  const [isQuizInitialized, setIsQuizInitialized] = useState(false);

  useEffect(() => {
    const savedState = localStorage.getItem('quizState');
    if (savedState) {
      const {
        currentQuestionIndex: savedIndex,
        quizCompleted: savedCompleted,
        questions: savedQuestions,
        answers: savedAnswers,
        quizResult: savedResult
      } = JSON.parse(savedState);

      setCurrentQuestionIndex(savedIndex || 0);
      setQuizCompleted(savedCompleted || false);
      setQuestions(savedQuestions || []);
      setAnswers(savedAnswers || {});
      setQuizResult(savedResult || null);
      setIsQuizInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (isQuizInitialized) {
      const stateToPersist = {
        currentQuestionIndex,
        quizCompleted,
        questions,
        answers,
        quizResult
      };
      localStorage.setItem('quizState', JSON.stringify(stateToPersist));
    }
  }, [currentQuestionIndex, quizCompleted, questions, answers, quizResult, isQuizInitialized]);

  const resetQuiz = () => {
    setCurrentQuestionIndex(0);
    setQuizCompleted(false);
    setQuestions([]);
    setAnswers({});
    setQuizResult(null);
    setIsQuizInitialized(false);
    localStorage.removeItem('quizState');
  };

  return (
    <QuizContext.Provider
      value={{
        currentQuestionIndex,
        setCurrentQuestionIndex,
        quizCompleted,
        setQuizCompleted,
        questions,
        setQuestions,
        answers,
        setAnswers,
        quizResult,
        setQuizResult,
        isQuizInitialized,
        setIsQuizInitialized,
        resetQuiz
      }}
    >
      {children}
    </QuizContext.Provider>
  );
};

export const useQuiz = () => useContext(QuizContext);