import styled from 'styled-components';
import theme from '../../theme';


export const ChatHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.isEmpty ? 'center' : 'flex-start'};
  align-items: center;
  height: 90%;
  width: 100%;
  overflow-y: auto;
  padding: ${theme.spacing.medium};
  background-color: ${theme.colors.background};
  border-radius: ${theme.spacing.small};

  /* Custom scrollbar styles */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.mediumGray};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }) => theme.colors.darkGray};
  }
`;

export const UnauthenticatedMessage = styled.p`
  margin-top: 100%;
  //margin-bottom: ${theme.spacing.large};
  text-align: center;
  color: ${theme.colors.text};
  cursor: pointer;
  transition: color 0.2s ease;
  max-width: 80%;

  &:hover {
    color: ${theme.colors.primary};
  }
`;

export const HistoryTitle = styled.h2`
  font-size: ${props => props.theme.fontSizes.large};
  font-weight: bold;
  margin-bottom: ${props => props.theme.spacing.medium};
  color: ${props => props.theme.colors.text};
`;

export const HistoryItem = styled.div`
  background-color: ${props => props.theme.colors.background};
  border-radius: ${props => props.theme.spacing.small};
  padding: ${props => props.theme.spacing.medium};
  margin-bottom: ${props => props.theme.spacing.small};
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: ${props => props.theme.colors.background};
    opacity: 0.8;
  }


`;

export const RenameInput = styled.input`
  flex-grow: 1;
  padding: ${props => props.theme.spacing.small};
  margin-right: ${props => props.theme.spacing.small};
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: ${props => props.theme.spacing.small};
`;

export const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: ${props => props.theme.spacing.small};
  color: ${props => props.theme.colors.text};
  transition: color 0.2s ease;

  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`;

export const DiscussionName = styled.span`
  flex-grow: 1;
  margin-right: ${props => props.theme.spacing.medium};
`;