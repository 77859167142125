import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BottomMenuContainer, MenuButton } from './styles';
import { TbSwipe } from "react-icons/tb";
import { HiOutlineChat } from "react-icons/hi";
import { FaRankingStar } from "react-icons/fa6";
import { useTheme } from 'styled-components';
import useDeviceType from '../useDeviceType'; // Import the hook

const BottomMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { isMobile } = useDeviceType(); // Use the hook

  const handleNavigation = (path) => {
    navigate(path);
  };

  const getIconColor = (path) => {
    if (path === '/classement') {
      return (location.pathname === '/classement' || location.pathname === '/login')
        ? theme.colors.primary
        : theme.colors.mediumGray;
    }
    return location.pathname === path ? theme.colors.primary : theme.colors.mediumGray;
  };

  // Only render the BottomMenu if it's a mobile device
  if (!isMobile) return null;

  return (
    <BottomMenuContainer>
      <MenuButton
        onClick={() => handleNavigation('/quiz')}
        active={location.pathname === '/quiz'}
      >
        <TbSwipe size={27} color={getIconColor('/quiz')} />
      </MenuButton>
      <MenuButton
        onClick={() => handleNavigation('/chat')}
        active={location.pathname === '/chat'}
        center
      >
        <HiOutlineChat size={27} color={getIconColor('/chat')} />
      </MenuButton>
      <MenuButton
        onClick={() => handleNavigation('/classement')}
        active={location.pathname === '/classement' || location.pathname === '/login'}
      >
        <FaRankingStar size={27} color={getIconColor('/classement')} />
      </MenuButton>
    </BottomMenuContainer>
  );
};

export default BottomMenu;