import styled from 'styled-components';
import { ThumbsUp, ThumbsDown, HelpCircle, Heart } from 'lucide-react';
import { FaRankingStar } from "react-icons/fa6";


export const QuizContainer = styled.div`
      display: flex;
      top: 5%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 100vh;
      width: 100%;
      padding: ${props => props.theme.spacing.large};
      padding-bottom: 30%;
      position: relative;
      overflow: hidden;

       ${props => !props.isMobile &&`

       `}
`;

export const BackgroundBall = styled.div`
  position: absolute;
  width: 150vw;
  height: 200vw;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.primary};
  bottom: -30%;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;

  ${props => !props.isMobile &&`
    position: absolute;
    width: 150vw;
    height: 100%;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.primary};
    bottom: -40%;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
         `}

`;

export const QuestionCard = styled.div`

  background-color: ${props => props.theme.colors.background};
  border-radius: 25px;
  padding: ${props => props.theme.spacing.xlarge};
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 85%;
  aspect-ratio: 1 / 1.1;
  display: flex;
  align-items: center;
  z-index: 2;
  top: 0%;
  position: relative;
  overflow-y: auto;


    ${props => !props.isMobile &&`
      position: relative;
      background-color: ${props => props.theme.colors.background};
      border-radius: 25px;
      padding: ${props => props.theme.spacing.xlarge};
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
      width: 80%;
      height: 60vh;
      display: flex;
      align-items: center;
      z-index: 2;
      top: 50%;
      overflow-y: auto;
      transform: translateX(10%);
      `}
`;

export const QuestionText = styled.p`
  font-size: ${props => props.theme.fontSizes.xlarge};
  color: ${props => props.theme.colors.primary};
  text-align: center;
  font-weight: ${props => props.theme.fontWeights.semiBold};
  z-index: 2;
  flex-grow: 1;
  max-height: 100%;
  margin: 0;
  padding: 0;
  flex-grow: 1;
  overflow-y: auto;
`;


export const QuizMessage = styled.div`
  font-size: ${props => props.theme.fontSizes.large};
  color: ${props => props.theme.colors.text};
  text-align: center;
  padding: ${props => props.theme.spacing.large};
  z-index: 2;
  background-color: ${props => props.theme.colors.background};
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  margin: ${props => props.theme.spacing.xlarge} auto;
`;


export const AnswerButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.background};
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  svg {
  width: 30px;
  height: 30px;
  color: ${props => props.theme.colors.primary};
  }
`;


export const QuizCompletionMessage = styled(QuizMessage)`
  font-size: ${props => props.theme.fontSizes.xlarge};
  font-weight: ${props => props.theme.fontWeights.semiBold};
  color: ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.lightGray};
  border: 2px solid ${props => props.theme.colors.primary};
  padding: ${props => props.theme.spacing.xlarge};

  ${props => !props.isMobile &&`
  margin-top: 35%;
      `}
`;

export const AnswerOptionsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;
  margin-bottom: -95%;
  z-index: 3;
  position: fixed;
  gap: 8%;

  ${props => !props.isMobile &&`
      position: absolute;
      margin-bottom: 5%;
      gap: 3%;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    `}
`;

const IconStyle = `
`;

export const StyledThumbsUp = styled(ThumbsUp)`
  ${IconStyle}
`;

export const StyledThumbsDown = styled(ThumbsDown)`
  ${IconStyle}
`;

export const StyledHelpCircle = styled(HelpCircle)`
  ${IconStyle}
`;

export const StyledHeart = styled(Heart)`
  ${IconStyle}
`;



export const ResultButton = styled(AnswerButton)`
  width: auto;
  height: auto;
  border-radius: 25px;
  padding: ${props => props.theme.spacing.medium} ${props => props.theme.spacing.large};
  font-size: ${props => props.theme.fontSizes.medium};
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.background};
  background-color: ${props => props.theme.colors.primary};
  margin-top: ${props => props.theme.spacing.large};

  &:hover {
    background-color: ${props => props.theme.colors.accent};
  }

  svg {
    margin-right: ${props => props.theme.spacing.small};
    color: ${props => props.theme.colors.background};
  }

  ${props => !props.isMobile &&`

      `}
`;

export const StyledRanking = styled(FaRankingStar)`
  color: ${props => props.theme.colors.background};
`;
