import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Container, SettingsButton, SettingsIcon } from './styles';
import Podium from '../Podium';
import apiCall from '../../utils/api';

const Classement = () => {
  const { user } = useAuth();
  const [quizResults, setQuizResults] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.pseudo) {
      fetchQuizResults();
    }
  }, [user]);

  const fetchQuizResults = async () => {
    try {
      const response = await apiCall(`${process.env.REACT_APP_BACKEND_URL}/quiz/result/`, 'GET');
      setQuizResults(response);
      // Fetch other results here when the API is ready
      // setOtherResults(response.otherResults);
    } catch (error) {
      console.error('Error fetching quiz results:', error);
      setQuizResults(null);
    }
  };

  const getDefaultResults = () => [
    { formation: "Pas encore de formation", score: 0 },
    { formation: "Pas encore de formation", score: 0 },
    { formation: "Pas encore de formation", score: 0 }
  ];

  const handleSettingsClick = () => {
    navigate('/settings');
  };

  const handleViewPreviousResults = () => {
    // Implement viewing previous results
    console.log("View previous results");
  };

  const handleRestartQuiz = () => {
    // Implement restarting the quiz
    console.log("Restart quiz");
  };

  return (
    <Container>
      <SettingsButton onClick={handleSettingsClick}>
        <SettingsIcon />
      </SettingsButton>
        <Podium
          results={quizResults ? [
            { formation: quizResults.top_formation_1, score: quizResults.score_1 },
            { formation: quizResults.top_formation_2, score: quizResults.score_2 },
            { formation: quizResults.top_formation_3, score: quizResults.score_3 }
          ] : getDefaultResults()}
          onViewPreviousResults={handleViewPreviousResults}
          onRestartQuiz={handleRestartQuiz}
        />
        {!quizResults && (
          <p>Vous n'avez pas encore de résultats de quiz. Essayez de répondre à un quiz pour voir vos résultats ici !</p>
        )}
    </Container>
  );
};

export default Classement;
