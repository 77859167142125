import React, { useState, useRef, useCallback  } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import ChatDiscussion from './components/ChatDiscussion';
import SideMenu from './components/SideMenu';
import BottomMenu from './components/BottomMenu';
import LoginPage from './components/LoginPage';
import Quiz from './components/Quiz';
import Profile from './components/Classement';
import Settings from './components/Settings';
import MobileHistoryTab from './components/MobileHistoryTab';
import PseudonymForm from './components/PseudonymForm';
import { AuthProviderWithNavigate, useAuth } from './contexts/AuthContext';
import { ChatProvider } from './contexts/ChatContext';
import useDeviceType from './components/useDeviceType';
import theme from './theme';
import GlobalStyles from './GlobalStyles';
import PWAInstallPrompt from './components/PWAInstallPrompt';
import { LoadingContainer, LoadingBar } from './components/LoadingStyles';
import './App.css';
import { QuizProvider } from './contexts/QuizContext';


function App() {
  const { user, loading, isAuthenticated } = useAuth();
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [isMobileHistoryOpen, setIsMobileHistoryOpen] = useState(false);
  const { isMobile } = useDeviceType();
  const location = useLocation();
  const chatDiscussionRef = useRef();

  const toggleMenu = () => {
    setIsSideMenuOpen(prevState => !prevState);
  };

  const toggleMobileHistory = () => {
    setIsMobileHistoryOpen(!isMobileHistoryOpen);
  };

  const handleDiscussionSelect = (discussion) => {
    if (chatDiscussionRef.current) {
      chatDiscussionRef.current.loadDiscussion(discussion.id);
    }
  };

  const ProtectedRoute = ({ children }) => {
    if (loading) {
      return <LoadingContainer><LoadingBar /></LoadingContainer>;
    }

    if (!isAuthenticated) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
  };

  const handleRestartChat = useCallback(() => {
      if (chatDiscussionRef.current) {
        chatDiscussionRef.current.resetChat();
      }
    }, []);

  const RootRedirect = () => {
    if (loading) {
      return <LoadingContainer><LoadingBar /></LoadingContainer>;
    }

    return <Navigate to="/chat" replace />;
  };

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <LoadingContainer>
          <LoadingBar />
        </LoadingContainer>
      </ThemeProvider>
    );
  }

  return (
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <AuthProviderWithNavigate>
          <ChatProvider>
            <QuizProvider>
              <div className="App">
                <PWAInstallPrompt />
                <SideMenu
                  isOpen={isSideMenuOpen}
                  onToggle={toggleMenu}
                  onDiscussionSelect={handleDiscussionSelect}
                  onRestartChat={handleRestartChat}
                />
                <main className={`content-area ${isMobile ? 'mobile' : 'desktop'} ${isSideMenuOpen ? 'side-menu-open' : ''}`}>
                  <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/verify-email" element={<LoginPage />} />
                    <Route path="/chat" element={
                      <ChatDiscussion
                        ref={chatDiscussionRef}
                        toggleMobileHistory={toggleMobileHistory}
                        isSideMenuOpen={isSideMenuOpen}
                        isMobile={isMobile}
                      />
                    } />
                    <Route path="/quiz" element={<Quiz />} />
                    <Route path="/classement" element={
                      <ProtectedRoute>
                        <Profile />
                      </ProtectedRoute>
                    } />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/set-pseudonym" element={
                      <ProtectedRoute>
                        <PseudonymForm />
                      </ProtectedRoute>
                    } />
                    <Route path="/" element={<RootRedirect />} />
                  </Routes>
                </main>
                {isMobile && (
                  <>
                    <BottomMenu user={user} />
                    {location.pathname === '/chat' && (
                      <MobileHistoryTab
                        isOpen={isMobileHistoryOpen}
                        onClose={toggleMobileHistory}
                      />
                    )}
                  </>
                )}
              </div>
            </QuizProvider>
          </ChatProvider>
        </AuthProviderWithNavigate>
      </ThemeProvider>
    );
  }

  export default App;