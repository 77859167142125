import styled from 'styled-components';
import { FaCog } from "react-icons/fa";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.spacing.large};
  max-width: 600px;
  margin: 0 auto;
  height: 100vh; /* Full height for center alignment */
  position: relative; /* For positioning the Logout button */
  background-color: ${props => props.theme.colors.background};
`;


export const LoginButton = styled.button`
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.background};
  border: none;
  border-radius: ${props => props.theme.spacing.small};
  padding: ${props => props.theme.spacing.medium};
  font-size: ${props => props.theme.fontSizes.medium};
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => props.theme.colors.secondary};
  }
`;

export const SettingsButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: rotate(15deg);
  }
`;

export const SettingsIcon = styled(FaCog)`
  font-size: 24px;
  color: ${props => props.theme.colors.primary};
`;
