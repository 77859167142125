import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { AuthProviderWithNavigate } from './contexts/AuthContext';

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <AuthProviderWithNavigate>
        <App />
      </AuthProviderWithNavigate>
    </Router>
  </React.StrictMode>
);

serviceWorkerRegistration.register();