import React, { createContext, useState, useContext, useEffect } from 'react';

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [chatHistory, setChatHistory] = useState([]);
  const [discussion, setDiscussion] = useState('');
  const [inputText, setInputText] = useState('');
  const [showWelcomeMenu, setShowWelcomeMenu] = useState(true);
  const [contextHistory, setContextHistory] = useState([]);
  const [beginDate, setBeginDate] = useState('');
  const [isChatInitialized, setIsChatInitialized] = useState(false);

  useEffect(() => {
    const savedState = localStorage.getItem('chatDiscussionState');
    if (savedState) {
      const {
        chatHistory: savedHistory,
        discussion: savedDiscussion,
        inputText: savedInput,
        contextHistory: savedContextHistory,
        beginDate: savedBeginDate
      } = JSON.parse(savedState);
      setChatHistory(savedHistory || []);
      setDiscussion(savedDiscussion || '');
      setInputText(savedInput || '');
      setContextHistory(savedContextHistory || []);
      setBeginDate(savedBeginDate || '');
      setShowWelcomeMenu((savedHistory || []).length === 0);
      setIsChatInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (isChatInitialized) {
      const stateToPersist = {
        chatHistory,
        discussion,
        inputText,
        contextHistory,
        beginDate
      };
      localStorage.setItem('chatDiscussionState', JSON.stringify(stateToPersist));
    }
  }, [chatHistory, discussion, inputText, contextHistory, beginDate, isChatInitialized]);

  const resetChat = () => {
    setChatHistory([]);
    setDiscussion('');
    setContextHistory([]);
    setBeginDate('');
    setShowWelcomeMenu(true);
    setIsChatInitialized(false);
    localStorage.removeItem('chatDiscussionState');
  };

  return (
    <ChatContext.Provider value={{
      chatHistory, setChatHistory,
      discussion, setDiscussion,
      inputText, setInputText,
      showWelcomeMenu, setShowWelcomeMenu,
      contextHistory, setContextHistory,
      beginDate, setBeginDate,
      isChatInitialized, setIsChatInitialized,
      resetChat
    }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => useContext(ChatContext);