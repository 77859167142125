// index.js
import React, { useState } from 'react';
import {
  WelcomeMenuWrapper,
  AppLogo,
  WelcomeMessage,
  WelcomeDescription,
  ExampleBox,
  ExamplesContainer,
} from './styles';

import appLogo from './meoria_logo.png';

const WelcomeMenu = ({ onExampleClick, isSideMenuOpen, isMobile }) => {
  const [imageError, setImageError] = useState(false);

  const examples = [
    'PASS, LAS, P0, je veux juste être médecin moi...',
    "J'adore coder, t'as une école pour moi ?",
    "J'ai déjà mon école ! Comment avoir la bourse ?",
    "Je veux faire du droit mais j'ai un dossier vraiment moyen...",
  ];

  const handleImageError = () => {
    console.error('Failed to load the image');
    setImageError(true);
  };

  return (
    <WelcomeMenuWrapper isSideMenuOpen={isSideMenuOpen} isMobile={isMobile}>
      {!imageError && (
        <AppLogo src={appLogo} alt="App Logo" onError={handleImageError} />
      )}
      <WelcomeMessage>Bienvenue chez Meoria !</WelcomeMessage>
      <WelcomeDescription>
        Pose-moi toutes tes questions sur l'orientation, et je ferai tout mon possible pour y répondre.
      </WelcomeDescription>
      <ExamplesContainer isSideMenuOpen={isSideMenuOpen} isMobile={isMobile}>
        {examples.map((example, index) => (
          <ExampleBox
            key={index}
            onClick={() => onExampleClick(example)}
            isSideMenuOpen={isSideMenuOpen}
            isMobile={isMobile}
          >
            {example}
          </ExampleBox>
        ))}
      </ExamplesContainer>
    </WelcomeMenuWrapper>
  );
};

export default WelcomeMenu;
