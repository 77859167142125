import React from 'react';
import {
  PodiumContainer,
  PodiumStep,
  FormationName,
  FormationScore,
  FirstPlace,
  SecondPlace,
  ThirdPlace,
  CardContent,
} from './styles';

const Podium = ({ results, onViewPreviousResults, onRestartQuiz }) => {
  const [first, second, third] = results;

  return (
      <CardContent>
        <PodiumContainer>
          <SecondPlace>
            <PodiumStep>
              <FormationName>{second.formation}</FormationName>
              <FormationScore>{second.score}</FormationScore>
            </PodiumStep>
          </SecondPlace>
          <FirstPlace>
            <PodiumStep>
              <FormationName>{first.formation}</FormationName>
              <FormationScore>{first.score}</FormationScore>
            </PodiumStep>
          </FirstPlace>
          <ThirdPlace>
            <PodiumStep>
              <FormationName>{third.formation}</FormationName>
              <FormationScore>{third.score}</FormationScore>
            </PodiumStep>
          </ThirdPlace>
        </PodiumContainer>
      </CardContent>
  );
};

export default Podium;